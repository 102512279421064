import { useState } from 'react'
import HeroImage from './assets/undraw_transfer_money.svg'
import People from "./assets/people2.png";
import ImgWhy from "./assets/why.png";
import Logo from "./assets/logo.png";

import PerfectMoney from "./assets/Logo/perfect-money-color.png";
import Paypal from "./assets/Logo/paypal.png";
import HiggsDomino from "./assets/Logo/higgs-dominos-color.png";
import HarvestGo from "./assets/Logo/harvest-go.png";
import FastPay from "./assets/Logo/fasapay-color.png";
import BinaryColor from "./assets/Logo/binary-color.png";

const Partner = [
  {
    title: "Perfect Money",
    image: PerfectMoney,
  },
  {
    title: "Paypal",
    image: Paypal,
  },
  {
    title: "Higgs Domino",
    image: HiggsDomino,
  },
  {
    title: "Harvest Go",
    image: HarvestGo,
  },
  {
    title: "Fastpay",
    image: FastPay,
  },
  {
    title: "BinaryColor",
    image: BinaryColor,
  },
]

const content = [
  {
    id: "01",
    title: "Fill out the form",
    description: "Fill out the purchase form completely.",
  },
  {
    id: "02",
    title: "Payment",
    description: "Payment is made via Paypal, etc transfer. After the transfer, please confirm the payment.",
  },
  {
    id: "03",
    title: "Validation",
    description: "Payment checking is done by the admin.",
  },
  {
    id: "04",
    title: "Delivery",
    description: "After the payment is validated, the balance will be sent immediately.",
  },
]

const contentWhy = [
  {
    title: "Fast Respond",
    description: "Admin at Exchange Corp fast respond.",
  },
  {
    title: "Trusted",
    description: "Many customers already trust Exchanger Corp.",
  },
  {
    title: "Chiper",
    description: "Products at Exchanger Corp are cheaper than products at other stores.",
  },
  {
    title: "Fast Send",
    description: "If the payment has been verified, the product will be sent immediately.",
  },
]

const optionProduct = [
  {
    title: "BUSD",
    value: "BUSD",
    status: null,
  },
  {
    title: "USDT",
    value: "USDT",
    status: null,
  },
]

const Header = () => {
  return (
    <header id="home" className="flex justify-center bg-gradient-to-r from-cyan-500 to-blue-500 text-white">
      <div className="container">
        <nav className="flex justify-between p-4">
          <div className="logo">
            <a href="#home">
              <h1 className="text-2xl font-semibold">Exchanger Corp</h1>
            </a>
          </div>
          <ul className="flex justify-between items-center space-x-4 text-xl font-semibold">
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
        <div className="flex justify-between items-center p-4">
          {/* Call to Action */}
          <div className="flex flex-col rounded-lg w-fit p-4">
            <h2 className="text-5xl font-bold">Convert Solutions</h2>
            <p className="text-xl mt-4">Simplifying Currency Exchange for Your Business</p>
            <a href='#contact' className="w-fit bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-6 rounded-full mt-4">
              Get a Quote Now!
            </a>
          </div>
          {/* Hero Image */}
          <div className="flex justify-center items-center p-4">
            <img src={HeroImage} alt="Hero" className="w-96" />
          </div>
        </div>
      </div>
    </header>
  )
}

const Feature = () => {
  return (
    <section id="features" className="flex justify-center bg-gray-50 py-20">
      <div className="container">
        <div className="flex flex-col items-center justify-center p-4">
          <h2 className="text-5xl mb-10 font-bold">Our Product</h2>
        </div>
        <div className="flex inline self-center items-center px-auto item-center justify-center space-x-3">
          {Partner.map((item, index) => {
            return (
              <img key={index + 1} src={item.image} alt={item.title} className="inline-flex h-8 md:h-10 lg:h-12" />
            )
          })}
        </div>
      </div>
    </section>
  )
}

const PayMethod = () => {
  return (
    <section id="method" className="flex justify-center py-10">
      <div className="container flex flex-col md:flex-row justify-center py-4 px-4 lg:px-32">
        <div className="w-full md:w-1/2 lg:1/3 flex items-center md:px-4">
          <img src={People} alt="people" className="w-full px-0 lg:px-8 my-8" />
        </div>
        <div className="w-full md:w-1/2 lg:2/3 flex flex-col justify-center">
          <h2 className="text-4xl font-bold mb-5">
            <span className="text-[#6D549F]">How to </span>
            <span className="text-[#FFBE1F]">Exchange</span>
          </h2>
          <p className="font-medium text-xl text-justify mb-20 text-fifth">
            Payment flow that can be made via Paypal, etc transfer.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-12">
            {content.map((item, index) => {
              return (
                <div className="bg-[#F9F1FD] rounded-3xl px-6 py-20" value={item.id} key={index + 1}>
                  <h4 className="font-bold text-lg mb-1 -mt-6 text-fifth">
                    {item.title}
                  </h4>
                  <p className="font-base text-sm text-sixth">
                    {item.description}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

const Testimonial = () => {
  return (
    <section id="testimonials" className="flex justify-center bg-gray-50 py-20">
      <div className="container">
        <div className="flex flex-col items-center justify-center p-4">
          <h2 className="text-5xl mb-10 font-bold">Testimonials</h2>
        </div>
        <div class="grid mb-8 border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 md:mb-12 md:grid-cols-2">
          <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-tl-lg md:border-r dark:bg-gray-800 dark:border-gray-700">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Admin Exchanger Corp fast respond.</h3>
              <p class="my-4 font-light">"If you care for your time, I hands down would go with this."</p>
            </blockquote>
            <figcaption class="flex items-center justify-center space-x-3">
              <img class="rounded-full w-9 h-9 border-2" src={Logo} alt="profile" />
              <div class="space-y-0.5 font-medium dark:text-white text-left">
                <div>Kristina Mahoney</div>
                {/* <div class="text-sm font-light text-gray-500 dark:text-gray-400">Developer at Open AI</div> */}
              </div>
            </figcaption>
          </figure>
          <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 rounded-tr-lg dark:bg-gray-800 dark:border-gray-700">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Admin Exchanger Corp fast respond.</h3>
              <p class="my-4 font-light">"If you care for your time, I hands down would go with this."</p>
            </blockquote>
            <figcaption class="flex items-center justify-center space-x-3">
              <img class="rounded-full w-9 h-9 border-2" src={Logo} alt="profile" />
              <div class="space-y-0.5 font-medium dark:text-white text-left">
                <div>Roberta Casas</div>
                {/* <div class="text-sm font-light text-gray-500 dark:text-gray-400">Lead designer at Dropbox</div> */}
              </div>
            </figcaption>
          </figure>
          <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 rounded-bl-lg md:border-b-0 md:border-r dark:bg-gray-800 dark:border-gray-700">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Admin Exchanger Corp fast respond.</h3>
              <p class="my-4 font-light">"If you care for your time, I hands down would go with this."</p>
            </blockquote>
            <figcaption class="flex items-center justify-center space-x-3">
              <img class="rounded-full w-9 h-9 border-2" src={Logo} alt="profile" />
              <div class="space-y-0.5 font-medium dark:text-white text-left">
                <div>Jese Leos</div>
                {/* <div class="text-sm font-light text-gray-500 dark:text-gray-400">Software Engineer at Facebook</div> */}
              </div>
            </figcaption>
          </figure>
          <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-gray-200 rounded-b-lg md:rounded-br-lg dark:bg-gray-800 dark:border-gray-700">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Admin Exchanger Corp fast respond.</h3>
              <p class="my-4 font-light">"If you care for your time, I hands down would go with this."</p>
            </blockquote>
            <figcaption class="flex items-center justify-center space-x-3">
              <img class="rounded-full w-9 h-9 border-2" src={Logo} alt="profile" />
              <div class="space-y-0.5 font-medium dark:text-white text-left">
                <div>Joseph McFall</div>
                {/* <div class="text-sm font-light text-gray-500 dark:text-gray-400">CTO at Google</div> */}
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    </section>
  )
}

const Why = () => {
  return (
    <section id="method" className="flex justify-center py-10">
      <div className="container flex flex-col md:flex-row justify-center py-4 px-4 lg:px-32">
        <div className="w-full md:w-1/2 lg:1/3 flex items-center md:px-4">
          <img src={ImgWhy} alt="Why Exchanger Corp" className="w-full px-0 lg:px-8 my-8" />
        </div>
        <div className="w-full md:w-1/2 lg:2/3 flex flex-col justify-center">
          <h2 className="text-4xl font-bold mb-20">
            <span className="text-[#6D549F]">Why </span>
            <span className="text-[#FFBE1F]">Exchanger Corp</span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-12">
            {contentWhy.map((item, index) => {
              return (
                <div className="bg-[#F9F1FD] rounded-3xl px-6 py-20" value={item.id} key={index + 1}>
                  <h4 className="font-bold text-lg mb-1 -mt-6 text-fifth">
                    {item.title}
                  </h4>
                  <p className="font-base text-sm text-sixth">
                    {item.description}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

const Contact = () => {
  const [name, setName] = useState('');
  const [products, setProducts] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [message, setMessage] = useState('');
  const [checkbox, setCheckbox] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!checkbox) {
      alert('Please agree to the terms and conditions');
      return;
    } else if (amount < 25) {
      alert('Sorry Minimum Payment 25 USD');
      return;
    }

    // Redirect to whatsapp
    window.location.href = `https://wa.me/15739694157?text=Hello, I'm ${name}%0A want to buy ${amount} of ${products}. ${email ? `%0AMy payment method is ${email}.` : ''} ${address ? `My payment method is ${address}.` : ''} ${message ? `%0ANote: ${message}.` : ''}`;
  }

  return (
    <section id="contact" className="flex justify-center bg-gray-50">
      <div className="container">
        <div className="flex justify-center items-center p-4">
          <div className="flex flex-col rounded-lg w-fit p-4">
            <h2 className="text-5xl font-bold">Contact Us</h2>
            <p className="text-xl mt-4">We are here to help you</p>
            <form className="flex flex-col mt-4" onSubmit={handleSubmit}>
              <label htmlFor="name" className="text-lg">Name</label>
              <input
                className="p-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-purple-600"
                id="name"
                name="name"
                onChange={(e) => setName(e.target.value)}
                placeholder='Your Name'
                required
                type="text"
              />
              <label htmlFor="product" className="text-lg mt-4">Payment Method</label>
              <select
                className="p-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-purple-600"
                defaultValue={''}
                id="product"
                name="product"
                onChange={(e) => setAddress(e.target.value)}
                required
              >
                <option value="" disabled>Select Payment Method</option>
                {optionProduct.map((item, index) => {
                  return (
                    <option key={index + 1} value={item.value} >{item.title}</option>
                  )
                })}
              </select>
              {/* if product is paypal then show email */}
              {products === 'Paypal' && (
                <>
                  <label htmlFor="email" className="text-lg mt-4">Email</label>
                  <input
                    className="p-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-purple-600"
                    id="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Your Email'
                    required
                    type="email"
                  />
                </>
              )}
              {/* if product is not paypal then show crypto address */}
              {products !== 'Paypal' && (
                <>
                  <label htmlFor="product" className="text-lg mt-4">Product</label>
                  <select
                    className="p-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-purple-600"
                    defaultValue={''}
                    id="product"
                    name="product"
                    onChange={(e) => setProducts(e.target.value)}
                  >
                    <option value="" disabled>Select Product</option>
                    <option value="FasaPay" disabled>FasaPay (Sold Out)</option>
                    <option value="Perfect Money" disabled>Perfect Money (Sold Out)</option>
                    <option value="Binary.com" disabled>Binary.com (Sold Out)</option>
                    <option value="Gold - Harvest Go">Gold - Harvest Go</option>
                  </select>
                </>
              )}
              <label htmlFor="amount" className="text-lg mt-4">Amount</label>
              <input
                className="p-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-purple-600"
                id="amount"
                name="amount"
                onChange={(e) => setAmount(parseInt(e.target.value))}
                required
                min="25"
                type="number"
              />
              <label htmlFor="message" className="text-lg mt-4">Message</label>
              <textarea
                className="p-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-purple-600"
                cols="30"
                id="message"
                name="message"
                onChange={(e) => setMessage(e.target.value)}
                rows="10"
              ></textarea>
              {/* confirm checkbox */}
              <div className="flex items-center mt-4">
                <input
                  className="mr-2"
                  id="confirm"
                  name="confirm"
                  onChange={(e) => setCheckbox(e.target.checked)}
                  required
                  type="checkbox"
                />
                <label htmlFor="confirm">I confirm that I have read and agree to the <a href="#" className="text-blue-600 hover:underline">Terms of Service</a> and <a href="#" className="text-blue-600 hover:underline">Privacy Policy</a></label>
              </div>
              <button className="w-fit bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-6 rounded-full mt-4">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

const Footer = () => {
  return (
    <footer className="flex bg-gray-800 text-white text-sm justify-center p-4">
      <p>
        Exchanger Corp. &copy; 2023 - All rights reserved.
      </p>
    </footer>
  )
}

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Feature />
        <PayMethod />
        <Testimonial />
        <Why/>
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default App;
